import React from 'react'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction
} from '@remix-run/node'
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useRouteError
} from '@remix-run/react'
import Container from '~/components/Container'
import Axeptio from '~/components/ExternalScript/Axeptio'
import PageError from '~/components/PageError'
import TailwindIndicator from '~/components/TailwindIndicator'
import {
  defaultNS,
  i18nextConfig,
  type Locale,
  PREFIXED_PATHS_BY_LOCALE
} from '~/i18n/config'
import { parseUnknownLocale } from '~/i18n/i18n.utils'
import { getLocaleFromRequest } from '~/i18n/i18next.server'
import Providers from '~/providers'
import { ModalProvider } from '~/stores/Modal/Modal.provider'
import { matchIsNodeProduction, matchIsReleasedVersion } from '~/utils/env'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { GOOGLE_TAG_MANAGER_ID, META_PIXEL_ID } from './constants/env'
import { ROUTES } from './constants/routes'
import stylesheet from './global.css?url'
import { useTrackingRegister } from './hooks/useTrackingRegister'
import { MetaPixel } from './third-parties/facebook'
import { GoogleTagManager } from './third-parties/google'
import { Hotjar } from './third-parties/hotjar'
import Webmarketer from './third-parties/webmarketer'

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: stylesheet },
    { rel: 'preload', href: stylesheet, as: 'style' },
    {
      rel: 'icon',
      href: '/favicon.svg',
      type: 'image/svg+xml'
    }
  ]
}

export const handle = {
  i18n: [defaultNS]
}

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  const unknownLocale = params.lang
  const localeParam = parseUnknownLocale(unknownLocale)

  if (!localeParam.success) {
    const language =
      (await getLocaleFromRequest(request)) ?? i18nextConfig.fallbackLng
    const prefixedLocalePath = PREFIXED_PATHS_BY_LOCALE[language as Locale]
    const url = new URL(request.url)
    url.pathname = prefixedLocalePath + url.pathname

    return redirect(url.pathname + url.search + url.hash, 301)
  }

  return null
}

export const meta: MetaFunction = ({ error }) => {
  if (error) {
    const isNotFound = isRouteErrorResponse(error) && error.status === 404

    return [
      {
        title: isNotFound ? 'Page not found' : 'An error occurred'
      }
    ]
  }

  return []
}

export const ErrorBoundary = () => {
  const { t } = useTranslation(['common'])
  const error = useRouteError()
  const isNotFound = isRouteErrorResponse(error) && error.status === 404

  captureRemixErrorBoundaryError(error)

  // We show a beautiful error page only in production mode, or when the error is a 404
  // Otherwise, let's help the developer to debug the error
  return matchIsNodeProduction() || isNotFound ? (
    <PageError
      title={
        isNotFound
          ? t('common:errors.pageNotFound.title')
          : t('common:errors.pageError.title')
      }
      description={
        isNotFound
          ? t('common:errors.pageNotFound.description')
          : t('common:errors.pageError.description')
      }
      LinkButtonProps={{
        to: ROUTES.home,
        children: t('common:backToHome'),
        testId: 'backHome'
      }}
      statusCode={isRouteErrorResponse(error) ? error.status : 500}
    />
  ) : (
    <Container className="flex flex-col gap-y-3 py-10 font-mono">
      <h1 className="text-3xl">Application error</h1>
      <p className="text-sm">(only in dev mode)</p>
      <pre
        style={{
          padding: '2rem',
          background: 'rgba(191, 85, 64, 0.1)',
          color: 'red',
          overflow: 'auto'
        }}
      >
        {isRouteErrorResponse(error)
          ? JSON.stringify(error, null, 2)
          : error instanceof Error
            ? (error.stack ?? error.message)
            : JSON.stringify(error, null, 2)}
      </pre>
    </Container>
  )
}

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()

  useTrackingRegister()

  return (
    <html lang={i18n.language} dir={i18n.dir()}>
      {matchIsReleasedVersion() ? (
        <>
          <GoogleTagManager gtmId={GOOGLE_TAG_MANAGER_ID} />
          <MetaPixel pixelId={META_PIXEL_ID} />
          <Webmarketer />
          <Hotjar />
        </>
      ) : null}
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
        <Meta />
        <Links />
      </head>
      <body className="font-body">
        <Providers>{children}</Providers>
        <ModalProvider />
        <ScrollRestoration />
        <Scripts />
        <TailwindIndicator />
        <Axeptio />
        <Toaster
          toastOptions={{
            position: 'bottom-center'
          }}
        />
      </body>
    </html>
  )
}

const App = () => {
  return <Outlet />
}

export default withSentry(App)
