import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { MotionConfig, useReducedMotion } from 'framer-motion'
import { queryClient } from '~/queries/config'
import { HeroUIProvider } from '@heroui/react'
import { QueryClientProvider } from '@tanstack/react-query'

const Providers = ({ children }: { children: React.ReactNode }) => {
  const isReducedMotion = useReducedMotion() ?? true

  return (
    <QueryClientProvider client={queryClient}>
      <HeroUIProvider disableAnimation={isReducedMotion}>
        <ParallaxProvider isDisabled={isReducedMotion}>
          <MotionConfig reducedMotion="user">{children}</MotionConfig>
        </ParallaxProvider>
      </HeroUIProvider>
    </QueryClientProvider>
  )
}

export default Providers
